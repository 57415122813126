import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  private stepper: Stepper;
  public form: FormGroup;
  public loading = false;

  constructor(fb: FormBuilder, private http: HttpClient, public router: Router, private toastr: ToastrService) {
   // window.location.href = 'https://go.pakati.io/';
    this.form = fb.group({
      'desc': ['', Validators.compose([Validators.required])],
      'name': ['', Validators.compose([Validators.required])],
      'lastname': ['', Validators.compose([Validators.required])],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'telephone': ['', Validators.compose([Validators.minLength(10)])],
      'business': ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      'idea': ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      'website': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      'budget': ['', Validators.compose([Validators.required])],
      'training': ['', Validators.compose([Validators.required])],
      'timeframe': ['', Validators.compose([Validators.required])],
      'info': ['', Validators.compose([])]
    });
  }

  next() {
    this.stepper.next();
  }

  ngOnInit(): void {
    this.stepper = new Stepper($('.bs-stepper')[0], { animation: true });
  }

  onSubmit() {
    this.loading = true;


    const headers = {
      'Content-Type': 'application/json'
    };
    this.http.post<any>(environment.ApiURL + 'appointments', this.form.value, { headers }).subscribe({
      next: data => {
        console.log(data);
        if (data && data.status) {
          if (data.status === 'success') {

            this.router.navigateByUrl('/apply/schedule');
          }
          else {
            this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
            console.error('Unknown Error', data);
          }
        }
        else {
          this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
          console.error('There was an error!', 'Invalid Response Received');
        }
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
        console.error('There was an error!', error);
      }
    });
  }
}
