import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class TrainingRegisterComponent implements OnInit {
  public form: FormGroup;
  public loading = false;

  constructor(private renderer: Renderer2, fb: FormBuilder, private http: HttpClient, public router: Router, private toastr: ToastrService) {
  //  window.location.href = 'https://go.pakati.io/';

    this.form = fb.group({
      'txtUser': ['', Validators.compose([Validators.required])],
      'txtEmail': ['', Validators.compose([Validators.email, Validators.required])]
    });
  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  ngOnInit(): void {
    this.renderExternalScript('assets/js/main.js').onload = () => {

    }
  }

  register() {
    this.loading = true;

    const contact = {
      contact: {
        email: this.form.controls['txtEmail'].value,
        firstName: this.form.controls['txtUser'].value
      }
    };
    const headers = {
      'Content-Type': 'application/json'
    };
    this.http.post<any>(environment.ApiURL + 'contacts', contact, { headers } ).subscribe({
      next: data => {
        console.log(data);
        if (data && data.status) {
          if (data.status === 'success') {
            var x = document.getElementsByClassName("mfp-bg");
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].classList.add("mfp-hide");
            }

            var element = document.getElementById("modal-popup-1");
            element.classList.add("mfp-hide");

            this.router.navigateByUrl('/training/video');
          }
          else {
            this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
            console.error('Unknown Error', data);
          }
        }
        else {
          this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
          console.error('There was an error!', 'Invalid Response Received');
        }
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.toastr.error('Oops, it seems like something went wrong! Please reload this page and try again, else reach out to us on support@pakati.io', 'Something went wrong!', { closeButton: true, timeOut: 20000 });
        console.error('There was an error!', error);
      }
    });

  }

}
