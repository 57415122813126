<section class="xs-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="xs-heading text-center">
          <img src="assets/images/logo.png" width="300px;" /><br /><br />
          <h2 class="section-title">Prepare For Your Call</h2><br />
          <h2 class="banner-subtitle">(watch this video to know how your should prepare for your call)</h2>
          <span class="line"></span>
        </div>
      </div>
    </div><!-- .row END -->

    
  </div><!-- .container END -->

</section>


<div>
  <div class="play">

    <!--<iframe id="vimeo-video" width="100%" height="100%" allow="autoplay" src="https://player.vimeo.com/video/338309695?muted=1&autoplay=1&title=0&byline=0&wmode=transparent&autopause=0" frameborder="0" wmode="opaque" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
    <h2 style="color: white;">Video Coming Soon...</h2>
    <img id="vimeo-id" src="assets/images/bg.webp" />
  </div>
</div>

