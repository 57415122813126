
<section class="xs-section-padding free-quote-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <div class="xs-heading text-center">
          <img src="assets/images/logo.png" width="300px;" />
          <br /><br /><br />
          <h2 class="section-subtitle">This is an application for a free one-on-one strategy call with our team at Pakati Software.</h2>
          <p>You’ll be able to work with the Pakati team personally to discuss your idea and get some clarity on the way forward on how to make your idea a reality.</p>
          <p>Once you apply, you'll be able to select a time to speak with our team to go over your idea and see if it's a good fit to work together.</p>
        </div><!-- .xs-heading END -->
        <div class="text-center">
          <br />
          <a href="apply/application" class="btn btn-primary style2 icon-right">START <i class="icon icon-arrow-right"></i></a>
          <p style="font-size: 11px;">9 Easy Steps</p>
        </div>
      </div>
    </div><!-- .row END -->
    
  </div><!-- .container END -->

</section>
