<section class="inner-banner-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="inner-banner-content">
          <h1 class="inner-banner-title">404 Error</h1>
          <ul class="breadcumbs list-inline">
            <li><a href="index.html">Home</a></li>
            <li>404</li>
          </ul>
          <span class="border-divider style-white"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="banner-image" style="background-image:url('assets/images/backgrounds/background-1.jpg')"></div>
</section>
<!--breadcumb end here--><!-- inner banenr end -->
<!-- 404 section -->
<section class="xs-section-padding" data-scrollax-parent="true">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto text-center">
        <div class="content-404">
          <h2 class="title-404">Oops <span>Sorry!</span> <br> We can't find that page!</h2>
          <p>Either Something went wrong or the page doesn't exist anymore</p>
          <a href="/" class="btn btn-primary">Back to Home</a>
        </div>
      </div>
      <div class="col-lg-8 mx-auto text-center">
        <div class="img-404">
          <img src="assets/images/404.png" data-scrollax="properties: { translateY: '-50%' }" alt="">
        </div>
      </div>
    </div><!-- .row END -->
  </div><!-- .container END -->
</section><!-- end 404 section -->
<!-- call to action section -->
<section class="call-to-action-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="call-to-action-content">
          <h2>Interested To Bring Your Software Idea To Life?</h2>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="btn-wraper">
          <a href="apply/register" class="btn btn-info icon-right"><i class="icon icon-arrow-right"></i>Get Started Now</a>
        </div>
      </div>
    </div><!-- .row END -->
  </div><!-- .container END -->
</section><!-- end call to action section -->
