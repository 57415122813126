<div class="vidheader">
  <div class="container">
    <div class="row">
      <div class="col-lg-1 mx-auto">
      </div>
      <div class="col-lg-10 mx-auto">
        <br />
        <img src="assets/images/webinar.gif" />
      </div>
      <div class="col-lg-1 mx-auto">
      </div>
    </div>
    <!--<div class="row">
      <div class="col-lg-1 mx-auto">
      </div>
      <div class="col-lg-5 mx-auto">

      </div>
      <div class="col-lg-5 mx-auto">
        <img src="assets/images/sound.png" style="height: 30px;" />
      </div>
      <div class="col-lg-1 mx-auto">
      </div>
    </div>-->
  </div>
</div>

<div>
  <div class="play">

    <!--<iframe id="vimeo-video" width="100%" height="100%" allow="autoplay" src="https://player.vimeo.com/video/338309695?muted=1&autoplay=1&title=0&byline=0&wmode=transparent&autopause=0" frameborder="0" wmode="opaque" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
    <h2 style="color: white;">Video Coming Soon...</h2>
    <img id="vimeo-id" src="assets/images/bg.webp" />
  </div>
</div>

<section class="xs-section service-info-section" data-scrollax-parent="true">
  <div class="container">
    <div class="row">

      <div class="col-lg-12 mx-auto">
        <div class="text-center">
          <br />
          <a href="apply/register" class="btn btn-primary style2 icon-right book">Book Your Free Strategy Session Now <i class="icon icon-arrow-right"></i></a>
        </div>
      </div>

    </div><!-- .row END -->

    <div class="row">
      <div class="col-lg-8 mx-auto">
        <p style="line-height: 100px;">&nbsp;<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />&nbsp;</p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 mx-auto">
        &copy; Pakati Software (Pty) Ltd <br />
        <hr />
        <a href="legal" target="_blank">Legal &amp; Disclaimers</a> <br />
        <p>
          This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is
          NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.
        </p>
      </div>
    </div><!-- .row END -->
  </div>
</section><!-- END testimonials section -->
