import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { ErrorComponent } from './error/error.component';

//apply to work with us
import { RegisterComponent } from './apply/register/register.component';
import { ApplicationComponent } from './apply/application/application.component';
import { ScheduleComponent } from './apply/schedule/schedule.component';
import { PrepareComponent } from './apply/prepare/prepare.component';

//training
import { TrainingRegisterComponent } from './training/register/register.component';
import { VideoComponent } from './training/video/video.component';
import { CasestudyComponent } from './training/casestudy/casestudy.component';

//legal
import { LegalComponent } from './legal/legal.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./weblayout/weblayout.module').then(mod => mod.WeblayoutModule)  },

  //apply
  { path: 'apply', component: RegisterComponent },
  { path: 'apply/register' , component: RegisterComponent },
 
  { path: 'apply/application', component: ApplicationComponent },
  { path: 'apply/schedule', component: ScheduleComponent },
  { path: 'apply/prepare', component: PrepareComponent },

  //training
  { path: 'training', component: CasestudyComponent },
  { path: 'training/register', component: CasestudyComponent },
  { path: 'training/video', component: VideoComponent },

  //legal
  { path: 'legal', component: LegalComponent },

  //error
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
