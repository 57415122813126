<ngx-loading [show]="loading"></ngx-loading>
<section class="xs-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="xs-heading text-center">
          <img src="assets/images/logo.png" width="300px;" />
        </div>
      </div>
    </div><!-- .row END -->

    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="bs-stepper shadow wizard">
          <div class="bs-stepper-header" role="tablist">
            <!-- your steps here -->
            <div class="step" data-target="#a">
              <button type="button" class="step-trigger" role="tab" aria-controls="a1" id="b1">
                <span class="bs-stepper-circle">1</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#b">
              <button type="button" class="step-trigger" role="tab" aria-controls="a2" id="b2">
                <span class="bs-stepper-circle">2</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#c">
              <button type="button" class="step-trigger" role="tab" aria-controls="a3" id="b3">
                <span class="bs-stepper-circle">3</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#d">
              <button type="button" class="step-trigger" role="tab" aria-controls="a4" id="b4">
                <span class="bs-stepper-circle">4</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#e">
              <button type="button" class="step-trigger" role="tab" aria-controls="a5" id="b5">
                <span class="bs-stepper-circle">5</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#f">
              <button type="button" class="step-trigger" role="tab" aria-controls="a6" id="b6">
                <span class="bs-stepper-circle">6</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#g">
              <button type="button" class="step-trigger" role="tab" aria-controls="a7" id="b7">
                <span class="bs-stepper-circle">7</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#h">
              <button type="button" class="step-trigger" role="tab" aria-controls="a8" id="b8">
                <span class="bs-stepper-circle">8</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#i">
              <button type="button" class="step-trigger" role="tab" aria-controls="a9" id="b9">
                <span class="bs-stepper-circle">9</span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <form (ngSubmit)="onSubmit()" [formGroup]="form" role="form" name="form1">
              <!-- your steps content here -->
              <div id="a" class="content" role="tabpanel" aria-labelledby="b1">
                <h2 class="section-subtitle">* Which of the following best describes you?</h2><br />
                <ul>
                  <!--<li><input type="radio" formControlName="desc" value="I have an idea for new software but it’s not been validated"> I have an idea for new software but it’s not been validated</li>-->
                  <li><input type="radio" formControlName="desc" value="I have a great idea for software and need a roadmap & quote to build it"> I have a great idea for software and need a roadmap & quote to build it</li>
                  <li><input type="radio" formControlName="desc" value="I have a roadmap for my software and need it built"> I have a roadmap for my software and need it built</li>
                  <li><input type="radio" formControlName="desc" value="I have existing software that needs changes or support"> I have existing software that needs changes or support</li>
                  <li><input type="radio" formControlName="desc" value="Help me figure it out"> Help me figure it out</li>
                </ul>
                <button *ngIf="form.controls['desc'].value" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="b" class="content" role="tabpanel" aria-labelledby="b2">
                <h2 class="section-subtitle">* What's your name?</h2><br />
                <div class="row">
                  <div class="col-md-6">
                    <input class="form-control" placeholder="First Name" type="text" formControlName="name">
                  </div>
                  <div class="col-md-6">
                    <input class="form-control" placeholder="Last Name" type="text" formControlName="lastname">
                  </div>
                </div>
                <br /><br />
                <h2 class="section-subtitle">* What's your BEST email address?</h2>
                <p>(We will send important info about your strategy session to this email)</p>
                <div class="row">
                  <div class="col-md-12">
                    <input class="form-control" placeholder="Email Address" type="email" formControlName="email">
                  </div>
                </div>
                <br /><br />
                <h2 class="section-subtitle">* Phone Number?</h2>
                <p>(for the call)</p>
                <div class="row">
                  <div class="col-md-12">
                    <input class="form-control" placeholder="Phone Number" type="tel" formControlName="telephone">
                  </div>
                </div>

                <button *ngIf="form.controls['name'].value && form.controls['lastname'].value && form.controls['email'].value && form.controls['email'].valid && form.controls['telephone'].value && form.controls['telephone'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="c" class="content" role="tabpanel" aria-labelledby="b3">
                <h2 class="section-subtitle">* Please tell us a little bit about your business</h2>
                <p>(who is your target market, what is your value proposition, what do you do)</p>
                <div class="row">
                  <div class="col-md-12">
                    <textarea class="form-control" placeholder="Please tell us a litte bit about your business" formControlName="business" rows="7"></textarea>
                  </div>
                </div>
                <button *ngIf="form.controls['business'].value && form.controls['business'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="d" class="content" role="tabpanel" aria-labelledby="b4">
                <h2 class="section-subtitle">* What brings you here and what are you looking for help with?</h2>
                <p>(what is your software idea / requirement &amp; how do you think we will be able to help you?)</p>
                <div class="row">
                  <div class="col-md-12">
                    <textarea class="form-control" placeholder="What brings you here and what are you looking for help with?" formControlName="idea" rows="7"></textarea>
                  </div>
                </div>

                <button *ngIf="form.controls['idea'].value && form.controls['idea'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="e" class="content" role="tabpanel" aria-labelledby="b5">
                <h2 class="section-subtitle">* What is your current business website address? (If you have one)</h2>
                <p>If you don’t have one, please type “no site”</p>
                <div class="row">
                  <div class="col-md-12">
                    <input class="form-control" placeholder="Website" type="text" formControlName="website">
                  </div>
                </div>
                <button *ngIf="form.controls['website'].value && form.controls['website'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="f" class="content" role="tabpanel" aria-labelledby="b6">
                <h2 class="section-subtitle">* What is your current Software Development budget for the next 12 months? </h2><br />
                <ul>
                  <li><input type="radio" formControlName="budget" value="Less than $2,000"> Less than $2,000 (R40,000)</li>
                  <li><input type="radio" formControlName="budget" value="$2,000-$10,000"> $2,000 - $10,000 (R40,000 - R200,000)</li>
                  <li><input type="radio" formControlName="budget" value="$10,000-$25,000"> $10,000 - $25,000 (R200,000 - R500,000)</li>
                  <li><input type="radio" formControlName="budget" value="$25,000-$50,000"> $25,000 - $50,000 (R500,000 - R1,000,000)</li>
                  <li><input type="radio" formControlName="budget" value="$50,000-$100,000"> $50,000 - $100,000 (R1,000,000 - R2,000,000)</li>
                  <li><input type="radio" formControlName="budget" value="$100,000 +"> $100,000 (R2,000,000) +</li>
                </ul>
                <button *ngIf="form.controls['budget'].value && form.controls['budget'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="g" class="content" role="tabpanel" aria-labelledby="b7">
                <h2 class="section-subtitle">* Have you ever had any bespoke / custom software developed, and are you familiar with the processes involved?</h2>
                <p>This will help us prepare better for the call</p>
                <ul>
                  <li><input type="radio" formControlName="training" value="Yes, I have had software developed before"> Yes, I have had software developed before</li>
                  <li><input type="radio" formControlName="training" value="No, I have no experience with this"> No, I have no experience with this</li>
                </ul>
                <button *ngIf="form.controls['training'].value && form.controls['training'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="h" class="content" role="tabpanel" aria-labelledby="b8">
                <h2 class="section-subtitle">* If you are accepted to work with Pakati Software, how soon can we get started?</h2>
                <p></p>
                <div class="row">
                  <div class="col-md-12">
                    <input class="form-control" placeholder="How soon can we get started?" type="text" formControlName="timeframe">
                  </div>
                </div>
                <button *ngIf="form.controls['timeframe'].value && form.controls['timeframe'].valid" type="button" (click)="next()" class="btn btn-primary br">Next <i class="icon icon-arrow-right"></i></button>
              </div>

              <div id="i" class="content" role="tabpanel" aria-labelledby="b9">
                <h2 class="section-subtitle">Please provide any additional information you think we should know before the call</h2>
                <p></p>
                <div class="row">
                  <div class="col-md-12">
                    <textarea class="form-control" placeholder="Additional Information" formControlName="info" rows="7"></textarea>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary br">Submit</button>
              </div>

            </form>
          </div>

        </div>


      </div>
    </div><!-- .row END -->
  </div><!-- .container END -->

</section>


