import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-prepare',
  templateUrl: './prepare.component.html',
  styleUrls: ['./prepare.component.css']
})
export class PrepareComponent implements OnInit {

  constructor(private renderer: Renderer2) {
    //window.location.href = 'https://go.pakati.io/';
  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  ngOnInit(): void {
    this.renderExternalScript('https://f.vimeocdn.com/js/froogaloop2.min.js').onload = () => {
      this.renderExternalScript('assets/js/vimeo.js').onload = () => {

      }
    }
  }

}
