<section class="xs-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="xs-heading text-center">
          <img src="assets/images/logo.png" width="300px;" />
        </div>
      </div>
    </div><!-- .row END -->

    <div class="row">
      <div class="col-lg-10 mx-auto text-center">
        <p>Please select a date and time for your free strategy session. We will email you a Zoom link to attend to the call.</p>
        

        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/pakati/pakati-software-strategy-session" style="min-width:320px;height:1300px;"></div>
       
        <!-- Calendly inline widget end -->

      </div>
    </div><!-- .row END -->
  </div><!-- .container END -->

</section>




