<ngx-loading [show]="loading"></ngx-loading>

<section class="xs-section service-info-section" data-scrollax-parent="true">
  <div class="container">
    <div class="row">

      <div class="col-lg-10 mx-auto">
        <div class="xs-heading text-center">
        <br />
          <h2 class="section-title">"How To Take Your <span>Software Idea</span> And Bring It To <span>Life</span> Without Getting Held Hostage By Your Tech Guy"</h2><br />
          <h2 class="banner-subtitle">(... even if you don't know anything about software development)</h2>
          <span class="line"></span>
        </div><!-- .xs-heading END -->

      </div>

    </div><!-- .row END -->

    <div class="row">

      <div class="col-lg-6 hideMobile">
        <div class="video-popup-wraper">
          
            <img src="assets/images/wb.jpg" alt="">
         
        </div>
      </div>

      <div class="col-lg-6 mx-auto">
        <div class="hiring-content">
          <h2 class="text-center" style="font-size: 21px;"><span>EXCLUSIVE TRAINING WITH ONE OF OUR FOUNDERS</span></h2>

          <ul class="xs-list checkbox">
            <li><b>VALIDATE YOUR IDEA: </b>How to know if your software idea will actually work in real life</li>
            <li><b>SECRET 2: </b>How to build a roadmap that is easy to understand</li>
            <li><b>SECRET 3: </b>The art of software development</li>
          </ul>
          <ul class="xs-list check">
            <li><b>FREE STRATEGY SESSION: </b>Stay till the very end of the training and I'll give you a free 30 minute strategy session!</li>
          </ul>
          <br />
          <div class="text-center">
            <a href="#modal-popup-1" class="btn btn-primary style2 icon-right xs-modal-popup">Yes! Reserve My Spot Now!<i class="icon icon-arrow-right"></i></a>
            <p style="font-size: 10px;">100% No Cost<br />Absolutely No Catch</p>
          </div>
        </div>
      </div>

    </div><!-- .row END -->

    <div class="row">

      <div class="col-lg-12">
        <div class="hiring-content" style="font-size: 18px;">
          <p>
            In this training, Ben shows how he went from loosing his job, selling grass seeds on the side of the road... to quickly creating a 7 figure Software Development Agency. All by running simple little social media ads for local businesses.
          </p><p>
            Hundreds of Ben's students have also built hyper profitable Software Development Agencies. The best part, most had no existing business experience when they started!
          </p><p>
            Ready to build a profitable business that actually helps people? Register now to learn how to start and grow your own hyper profitable Software Development Business even if you don't have any existing marketing experience...
          </p>
        </div>
      </div>
    </div><!-- .row END -->
  </div><!-- .container END -->
</section>

<section class="xs-section-padding testimonial-section" id="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="xs-heading text-center">
          <span class="line"></span><br><br /><br />
          <!--<h2 class="section-subtitle">Client’s love</h2>-->
          <h3 class="section-title ">What Others Are Saying About This FREE Class</h3>
        </div><!-- .xs-heading END -->
      </div>
    </div><!-- .row END -->
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="testimonial-slider-preview owl-carousel" id="sync3">
          <div class="single-testimonial-preview">
            <p>“Ben &amp; Simon's team always deliver work of the highest standard. They formed a critical part in helping us grow to the company we are today.”</p>
            <span class="border-line"></span>
          </div><!-- .single-testimonial-preview END -->
          <div class="single-testimonial-preview">
            <p>“These guys have a very special ability to listen and translate our requirements into workable specs and solutions. Brilliant!”</p>
            <span class="border-line"></span>
          </div><!-- .single-testimonial-preview END -->
          <div class="single-testimonial-preview">
            <p>“After you helped us with our mobile app we were able to grow our business exponentially and were recently valued at over $100 million! It would never have been possible without your great work.”</p>
            <span class="border-line"></span>
          </div><!-- .single-testimonial-preview END -->

        </div>
        <div class="testimonial-slider-thumb owl-carousel" id="sync4">
          <div class="single-bio-thumb">
            <div class="bio-image">
              <img src="assets/images/testimonial/theuns.jpg" alt="">
            </div>
            <div class="bio-info">
              <h4 class="small">Theuns Bezuidenhout</h4>
              <p>General Manager</p>
            </div>
          </div><!-- .single-bio-thumb END -->
          <div class="single-bio-thumb">
            <div class="bio-image">
              <img src="assets/images/testimonial/testimonial-2.jpg" alt="">
            </div>
            <div class="bio-info">
              <h4 class="small">Sarah Smith</h4>
              <p>CEO</p>
            </div>
          </div><!-- .single-bio-thumb END -->
          <div class="single-bio-thumb">
            <div class="bio-image">
              <img src="assets/images/testimonial/testimonial-1.jpg" alt="">
            </div>
            <div class="bio-info">
              <h4 class="small">Jacob Louw</h4>
              <p>Technical Founder</p>
            </div>
          </div><!-- .single-bio-thumb END -->

        </div>
      </div>
    </div><!-- .row END -->


  </div><!-- .container END -->
  <div class="big-watermark-icon small-version">
    <i class="icon icon-quote"></i>
  </div>
</section><!-- END testimonials section -->

<section class="xs-section-padding service-info-section" data-scrollax-parent="true">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 hideMobile">
        <img src="assets/images/arrow-left.png" />
      </div>
      <div class="col-lg-6 mx-auto">
        <div class="xs-heading text-center">
          <span class="line"></span><br><br /><br />
          <h2 class="section-subtitle">Are You Ready To Take Your Business To The Next Level?</h2>
          <!--<h3 class="section-title ">Are You Ready To Take Your Business To The Next Level?</h3>-->
        </div><!-- .xs-heading END -->
        <div class="text-center">
          <br />
          <a href="#modal-popup-1" class="btn btn-primary style2 icon-right xs-modal-popup">Yes! Reserve My Spot Now!<i class="icon icon-arrow-right"></i></a>
          <p style="font-size: 10px;">100% No Cost<br />Absolutely No Catch</p>
        </div>
      </div>
      <div class="col-lg-3 hideMobile">
        <img src="assets/images/arrow-right.png" />
      </div>
    </div><!-- .row END -->

    <div class="row">
      <div class="col-lg-8 mx-auto">
        <p style="line-height: 100px;">&nbsp;<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />&nbsp;</p>
      </div>
    </div>

      <div class="row">
        <div class="col-lg-8 mx-auto">
          &copy; Pakati Software (Pty) Ltd <br />
          <hr />
          <a href="legal" target="_blank">Legal &amp; Disclaimers</a> <br />
          <p>
            This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is
            NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.
          </p>
        </div>        
      </div><!-- .row END -->
    </div>
</section><!-- END testimonials section -->

<div class="zoom-anim-dialog mfp-hide modal-language" id="modal-popup-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="language-content">
        <div class="row">
          <div class="col-lg-10 mx-auto pop text-center">
            <img src="assets/images/50.png" />
            <h4>Where Should We Send a Link to the Training? Enter Your BEST Email So You Get it!</h4><br />
            <form class="xs-from" id="xs-contact-form" role="form" name="form1" [formGroup]="form" (ngSubmit)="register()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" name="name" id="xs_contact_name" class="form-control" placeholder="Name" formControlName="txtUser">
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="email" name="email" id="xs_contact_email" class="form-control" placeholder="Email Address" formControlName="txtEmail">
                  </div>
                </div>
              </div><!-- .row END -->
              <div class="text-center">
                <button type="submit" name="submit" class="btn btn-primary style2" style="width: 100%;" id="xs_contact_submit" [disabled]="form.invalid">Access the Training NOW</button>
              </div>
            </form>
            <br />
            <p style="font-size: 10px; color: grey;">We DO NOT tolerate SPAM and will never share your email.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
