<ngx-loading [show]="loading"></ngx-loading>

<section class="xs-section service-info-section" data-scrollax-parent="true">
  <div class="container">
    <div class="row">

      <div class="col-lg-10 mx-auto">
        <div class="xs-heading text-center">
          <br /><img src="assets/images/logo.png" width="200px;" /><br /><br />
          <h2 class="section-title">
            [Case Study] How Theuns <span>Exploded His Business</span> By Using Custom Software
          </h2>
          <br /><h2 class="banner-subtitle" style="font-size: 18px;"><span>NO COST CASE STUDY FOR BUSINESSES</span></h2>
       
        </div><!-- .xs-heading END -->

      </div>

    </div><!-- .row END -->

    <div class="row">
      <div class="col-lg-10 mx-auto text-center">
        <div class="video-popup-wraper">
          <a href="#modal-popup-1" class="xs-modal-popup"><img src="assets/images/wb.jpg" alt=""></a>
          
        </div>
      </div>
    </div><!-- .row END -->

    <div class="row">

      <div class="col-lg-6 mx-auto">

        <div class="text-center">
          <br />
          <a href="#modal-popup-1" class="btn btn-primary style2 icon-right xs-modal-popup">YES! Watch The Case Study Now!<i class="icon icon-arrow-right"></i></a>
          <p style="font-size: 10px;">100% No Cost<br />Absolutely No Catch</p>
        </div>
      </div>

    </div><!-- .row END -->

  </div><!-- .container END -->
</section>


<section class="xs-section-padding service-info-section" data-scrollax-parent="true">
  <div class="container">
    

    <div class="row">
      <div class="col-lg-8 mx-auto">
        <p style="line-height: 100px;">&nbsp;<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />&nbsp;</p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 mx-auto">
        &copy; Pakati Software (Pty) Ltd <br />
        <hr />
        <a href="legal" target="_blank">Legal &amp; Disclaimers</a> <br />
        <p>
          This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is
          NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.
        </p>
      </div>
    </div><!-- .row END -->
  </div>
</section><!-- END testimonials section -->

<div class="zoom-anim-dialog mfp-hide modal-language" id="modal-popup-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="language-content">
        <div class="row">
          <div class="col-lg-10 mx-auto pop text-center">
            <img src="assets/images/50.png" />
            <h4>Where Should We Send a Link to the Case Study? Enter Your BEST Email So You Get it!</h4><br />
            <form class="xs-from" id="xs-contact-form" role="form" name="form1" [formGroup]="form" (ngSubmit)="register()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" name="name" id="xs_contact_name" class="form-control" placeholder="Name" formControlName="txtUser">
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="email" name="email" id="xs_contact_email" class="form-control" placeholder="Email Address" formControlName="txtEmail">
                  </div>
                </div>
              </div><!-- .row END -->
              <div class="text-center">
                <button type="submit" name="submit" class="btn btn-primary style2" style="width: 100%;" id="xs_contact_submit" [disabled]="form.invalid">YES! Watch The Case Study Now!</button>
              </div>
            </form>
            <br />
            <p style="font-size: 10px; color: grey;">We DO NOT tolerate SPAM and will never share your email.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
