import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { RegisterComponent } from './apply/register/register.component';
import { ApplicationComponent } from './apply/application/application.component';
import { ScheduleComponent } from './apply/schedule/schedule.component';
import { ErrorComponent } from './error/error.component';
import { VideoComponent } from './training/video/video.component';
import { TrainingRegisterComponent } from './training/register/register.component';
import { LegalComponent } from './legal/legal.component';
import { CasestudyComponent } from './training/casestudy/casestudy.component';
import { PrepareComponent } from './apply/prepare/prepare.component';


@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    ApplicationComponent,
    ScheduleComponent,
    ErrorComponent,
    VideoComponent,
    LegalComponent,
    TrainingRegisterComponent,
    CasestudyComponent,
    PrepareComponent
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    HttpClientModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
